import { ColumnFlex, FlexCentered, RowFlex } from '@/themes/genericStyles'
import styled from 'styled-components'

export const Container = styled.div`
    ${ColumnFlex}
    ${FlexCentered}
    overflow-y: auto;
    width: 100%;
`

export const LivestreamHeaderContainer = styled.div`
    ${ColumnFlex}
    ${FlexCentered}
    background: ${(props) =>
        `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 62.56%),url(${props.imageURL})`}
    no-repeat;
    background-size: cover;
    padding: 0 4.44%;
    // border-bottom: 1px solid #202020;
    width: 100%;
    @media (min-width: 760px) {
        background-position: top center;
    }
    // background-position: center center;
    overflow: auto;
    position: relative;

    &:before {
        ${ColumnFlex}
        ${FlexCentered}
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;
        // display: block;
        background: ${(props) =>
                `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 62.56%),url(${props.imageURL})`}
            no-repeat;
        background-size: cover;
        // border-bottom: 1px solid #202020;
        // background-position: center center;
        @media (min-width: 760px) {
            background-position: top center;
        }
        width: 100%;
        height: 100%;
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
        backdrop-filter: blur(5px);
    }
`

export const LivestreamDetailContainer = styled.div`
    ${ColumnFlex}
    ${FlexCentered}
    z-index: 1;
    width: 100%;
    margin-top: 10%;
`

export const LivestreamHostImage = styled.div`
    position: relative;
    img {
        border-radius: 138px;
        object-fit: cover;
    }
`

export const BackButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 15px;
    position: fixed;
    top: 0px;
    width: 100%;
    // background-color: rgb(9, 9, 9);
    -webkit-box-align: center;
    align-items: center;
    z-index: 3;
    @media (min-width: 992px) {
        width: 698px;
    }
`

export const BackButton = styled.img`
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 10px;
`

export const HeaderText = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    color: #fafafa;
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const SearchImage = styled.img`
    height: 18px;
    width: 18px;
    cursor: pointer;
`

///host container

export const InfoContainer = styled.div`
    /* Frame 52 */

    /* Auto layout */
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    gap: 16px;

    width: 100%;
    margin-top: 10px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

export const InnerInfoContainer = styled.div`
    /* Frame 245 */

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;

    width: 203px;
    height: 19px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const HostContainer = styled.div`
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;

    width: 203px;
    height: 19px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`
export const HostInnerContainer = styled.div`
    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    width: 203px;
    height: 19px;

    /* Inside auto layout */

    flex: none;
    order: 0;
`

export const HostContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 4px;

    width: 203px;
    height: 19px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`
export const IconAndTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 2px;

    // width: 159px;
    height: 19px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const IconBadge = styled.img`
    width: 10px;
    height: 10px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const Title = styled.div`
    // width: 147px;
    height: 19px;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    /* or 19px */

    letter-spacing: 0.004em;

    color: #2b76f3;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

export const TagContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 9px;

    // width: 40px;
    // height: 16px;

    /* Black/600 */

    background: #1f1f1f;
    border-radius: 100px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

export const TagText = styled.div`
    // width: 22px;
    // height: 12px;

    /* Overline/Regular/Title Case */

    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
    /* identical to box height, or 12px */

    letter-spacing: 0.015em;

    /* White/100 */

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`
//Liveroom details
export const LiveRoomDetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 4px;

    width: 100%;
    // height: 88px;
    z-index: 2;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

export const LiveroomDetailInnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 4px;

    // width: 147px;
    // height: 18px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const TimeContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 4px;
    gap: 2px;

    // width: 93px;
    // height: 18px;

    /* Black/600 */

    background: #1f1f1f;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`
export const TimeOutline = styled.img`
    width: 12px;
    height: 12px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`
export const TimeInfo = styled.div`
    // width: 71px;
    // height: 14px;

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    /* or 14px */

    letter-spacing: 0.004em;

    /* White/100 */

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

export const LiveTagContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 4px;
    gap: 2px;

    // width: 47px;
    // height: 18px;

    /* Black/600 */

    background: #1f1f1f;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const EqualizerIcon = styled.img`
    width: 12px;
    height: 12px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const LiveText = styled.div`
    // width: 25px;
    // height: 14px;

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    /* or 14px */

    letter-spacing: 0.004em;

    /* White/100 */

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

export const LiveListenerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 4px;
    gap: 2px;

    /* Black/600 */

    background: #1f1f1f;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
`

export const HeadphonesIcon = styled.img`
    width: 12px;
    height: 12px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const ParticipantCountText = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    /* or 14px */

    letter-spacing: 0.004em;

    /* White/100 */

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

export const Ellipse = styled.div`
    width: 4px;
    height: 4px;
    border-radius: 10px;
    /* Black/400 */

    background: ${(props) => (props.color ? props.color : '#333333')};

    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
`

export const RecordedRoomBadge = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 4px;
    gap: 2px;

    // width: 41px;
    // height: 18px;

    /* Black/600 */

    background: #1f1f1f;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
`

export const RecordIcon = styled.img`
    width: 8px;
    height: 8px;
    border-radius: 10px;

    /* Jasper/500 */

    background: #dc3c43;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const RecordText = styled.div`
    // width: 23px;
    // height: 14px;

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    /* or 14px */

    letter-spacing: 0.004em;

    /* White/100 */

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

export const VIPLiveBadge = styled.div`
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 4px;
    gap: 2px;

    // width: 42px;
    // height: 18px;

    background: #1f1f1f;
    /* Candle Light/500 */

    border: 0.5px solid #fad810;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
`

export const VIPStarIcon = styled.img`
    width: 12px;
    height: 12px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const VIPText = styled.div`
    // width: 20px;
    // height: 14px;

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    /* or 14px */

    letter-spacing: 0.004em;

    /* Candle Light/500 */

    color: #fad810;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

export const VIPTitle = styled.div`
    width: 100%;
    // height: 22px;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    text-align: center;
    letter-spacing: 0.01em;

    color: #fafafa;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

export const LiveroomDescription = styled.div`
    width: 100%;
    // height: 40px;

    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
`

export const LiveroomDescriptionText = styled.div`
    // position: absolute;
    width: 100%;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* or 20px */

    text-align: center;
    letter-spacing: 0.01em;

    /* Medium Grey/300 */

    color: #8f8f8f;
`

// ticket price and buttons

export const TicketPriceAndButtonMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;

    width: 100%;
    // height: 148px;

    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
`

export const TicketPriceAndButtonInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 4.44%;
    gap: 8px;

    width: 100%;
    // height: 148px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const TicketPriceMainContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 12px;
    gap: 12px;

    width: 100%;
    height: 36px;

    /* Black/500 */

    background: #292929;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const TicketCodeAvailableText = styled.div`
    // width: 226px;
    height: 20px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    letter-spacing: 0.01em;

    /* White/600 */

    color: #cccccc;

    /* Inside auto layout */

    // flex: none;
    order: 0;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`

export const TicketPrice = styled.div`
    width: 66px;
    height: 20px;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    text-align: right;
    letter-spacing: 0.01em;

    /* White/100 */

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

//Action buttons

export const ButtonMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 4.44%;
    gap: 12px;

    width: 100%;
    height: 80px;

    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
`

export const RedeemButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    cursor: pointer;

    width: 100%;
    height: 48px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const RedeemButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15.5px 16px;
    gap: 8px;
    cursor: pointer;

    // width: 328px;
    height: 48px;

    /* Candle Light/500 */

    background: ${(props) => (props.swap ? 'unset' : '#fad810')};
    border-radius: 4px;
    border: ${(props) => (props.swap ? '2px solid #CCCCCC' : 'none')};

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
`

export const RedeemButtonText = styled.div`
    height: 17px;

    /* Button/Bold/Upper Case */

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    /* identical to box height, or 17px */

    letter-spacing: 0.2em;
    text-transform: uppercase;

    /* Black/900 */

    color: ${(props) => (props.swap ? '#EBEBEB' : '#000000')};

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

export const DisabledButtonMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 4.44%;
    gap: 12px;

    width: 100%;
    height: 80px;

    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
`

export const DisabledButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    cursor: pointer;

    width: 100%;
    height: 48px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const DisabledButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15.5px 16px;
    gap: 8px;

    // width: 328px;
    height: 48px;

    /* Candle Light/500 */

    background: #333333;
    border: none;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
`

export const DisabledButtonText = styled.div`
    height: 17px;

    /* Button/Bold/Upper Case */

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    /* identical to box height, or 17px */

    letter-spacing: 0.2em;
    text-transform: uppercase;

    /* Black/900 */

    color: #000000;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

export const BuyNowMessage = styled.div`
    // width: 328px;
    height: 20px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    text-align: center;
    letter-spacing: 0.01em;

    /* White/700 */

    color: #c2c2c2;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

//countdown

export const CountdownMainContainer = styled.div`
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 4.44%;
    gap: 16px;

    width: 100%;
    height: 97px;
    margin-top: 20px;

    /* Inside auto layout */

    flex: none;
    order: 3;
    flex-grow: 0;
`

export const CountdownHeaderMainContainer = styled.div`
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // padding: 0px 16px;
    gap: 16px;

    width: 100%;
    height: 28px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const CountdownHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    width: 328px;
    height: 28px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const CountdownHeaderText = styled.div`
    width: 328px;
    height: 28px;

    /* Font / Title H6 */

    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    /* identical to box height, or 28px */

    color: #fafafa;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
`

export const CountdownDetailMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 14px 16px;
    gap: 10px;

    width: 100%;
    height: 53px;

    background: #202020;
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`
export const CountdownDetailContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 26px;

    width: 100%;
    height: 25px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
`

export const CountdownTimerContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;

    width: 100%;
    height: 25px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
`

export const CountdownTimerText = styled.div`
    width: 100%;
    // height: 25px;

    /* Font / Subtitle 1 */

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */

    color: #c4c4c4;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

//user list
export const UserListMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 4.44%;
    gap: 16px;

    width: 100%;
    // height: 280px;
    margin-top: 20px;

    /* Inside auto layout */

    flex: none;
    order: 4;
    flex-grow: 0;
    margin-bottom: 40%;
    @media (min-width: 760px) {
        margin-bottom: 20%;
    }
`

export const UserListInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    width: 100%;
    // height: 280px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const UserListHeadingContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    width: 328px;
    height: 28px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`
export const UserListHeadingText = styled.div`
    width: 328px;
    height: 28px;

    /* Font / Title H6 */

    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    /* identical to box height, or 28px */

    color: #fafafa;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
`

export const UserGridDataContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;

    // display: flex;
    // flex-direction: row;
    align-items: center;
    padding: 0px;

    width: 100%;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    @media (min-width: 760px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    @media (max-width: 280px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`

export const SingleUserContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 4px;
    gap: 4px;

    // width: 82px;
    height: 110px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`
export const UserImageContainer = styled.div`
    width: 58px;
    height: 58px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`
export const UserImage = styled.div`
    left: 12px;
    top: 8px;

    img {
        border-radius: 48px;
        object-fit: cover;
    }
`
export const UsernameContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 2px;

    width: 59px;
    height: 12px;
    white-space: nowrap;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`
export const UsernameText = styled.div`
    height: 12px;

    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 120%;
    /* identical to box height, or 12px */

    letter-spacing: 0.015em;

    /* White/300 */
    color: ${(props) => (props.isVerified ? '#4285F4' : '#ebebeb')};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const VerifiedUserIcon = styled.img`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px;
    gap: 10px;

    width: 10px;
    height: 10px;

    /* Dodger Blue/700 */

    background: #2b76f3;
    border-radius: 100px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

export const UserRole = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 4px;
    gap: 2px;

    /* Black/600 */

    background: #1f1f1f;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
`

export const UserRoleText = styled.div`
    /* Overline/Regular/Title Case */

    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
    /* identical to box height, or 12px */

    letter-spacing: 0.015em;

    /* White/700 */

    color: #c2c2c2;
    text-transform: capitalize;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const FollowAndShareContainer = styled.div`
    ${RowFlex}
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 7.5vh;
    margin: 3.125vh 0 2.5vh 0;
    padding: 0 4.44%;
`
export const CancelledEndedContainer = styled.div`
    ${RowFlex}
    ${FlexCentered}
    // background: #FAD810;
    border-radius: 4px;
    width: 100%;
    height: inherit;

    padding: 12px 46px;
    border: 1px solid white;
`

export const CancelledEndedText = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: white;
`

export const FollowContainer = styled.div`
    ${RowFlex}
    ${FlexCentered}
    // background: #FAD810;
    border-radius: 4px;
    width: 80.22%;
    height: inherit;
    cursor: pointer;
    padding: 12px 46px;
    background: ${(props) => (props.swap ? 'unset' : '#fad810')};
    border: ${(props) => (props.swap ? '2px solid #CCCCCC' : 'none')};
`

export const FollowIcon = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 5px;
`

export const FollowText = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: ${(props) => (props.swap ? '#EBEBEB' : '#1e222c')};
`

export const ShareIconContainer = styled.div`
    width: 17.55%;
    height: inherit;
    border: 1px solid #ffffff;
    border-radius: 4px;
    ${RowFlex}
    ${FlexCentered}
    cursor: pointer;
`
export const ShareIcon = styled.img`
    width: 16px;
    height: 16px;
`
